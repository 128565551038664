<template>

    <div class="page-container">
        <tab @tab-switch="tabSwitch" :is-show-line="false">
            <nut-tab-panel :tab-title="item.lable" v-for="(item,index) in catalog" :key="index">
                <div :style="{height:scrollHeight+'px',overflow:'hidden'}">
                    <rsck-refresh :ref="'refresh'+'_'+index" :id="'container'+'_'+index" :height="scrollHeight"
                                  @onPullDown="onPullDown" @onPullUp="onPullUp" :style="{top:tabHeight+'px'}"
                                  :space-height="80">
                        <template v-if="index == 0">
                            <coupon-card-v1 :class="[index == dataList[index].length - 1 ? 'marginBottomZero' : '']"
                                            v-for="(data,dataIndex) in dataList[index]" :card="data"
                                            @onClick="goToUsePage(data)" :show-cover="true">
                                <div class="small-info">
                                    <div class="expire-time">有效期限：{{data.expires_time}}</div>
                                    <div class="rules" @click.stop="viewCardRules(index,dataIndex)">使用规则></div>
                                </div>
                                <div class="buttons" v-if="index < 2 && [1,2].includes(data.status) ">
                                    <div class="send" v-if="data.canTransfer"
                                         @click="scanQRCode(data.gift_card_id,data.id,data.card_code)"
                                    >
                                        转赠
                                    </div>
                                    <div class="use" @click.stop="goToUsePage(data)">
                                        立即使用
                                    </div>
                                </div>
                            </coupon-card-v1>
                        </template>
                        <template v-if="index == 1">
                            <coupon-card-v1 :class="[index == dataList[index].length - 1 ? 'marginBottomZero' : '']"
                                            v-for="(data,dataIndex) in dataList[index]" :card="data"
                                            @onClick="goToUsePage(data.id)">
                                <div class="small-info">
                                    <div class="expire-time">有效期限：{{data.expires_time}}</div>
                                    <div class="rules" @click.stop="viewCardRules(index,dataIndex)">使用规则></div>
                                </div>
                                <div class="buttons" v-if="index < 2">
                                    <div class="send" v-if="data.canTransfer"
                                         @click="scanQRCode(data.gift_card_id,data.id,data.card_code)"
                                    >
                                        转赠
                                    </div>
                                    <div class="use" @click.stop="goToUsePage(data)">
                                        立即使用
                                    </div>
                                </div>
                            </coupon-card-v1>
                        </template>
                        <template v-if="index == 2">
                            <coupon-card-v1 :class="[index == dataList[index].length - 1 ? 'marginBottomZero' : '']"
                                            v-for="(data,dataIndex) in dataList[index]" :card="data"
                                            @onClick="goToUsePage(data)">
                                <div class="small-info">
                                    <div class="expire-time">有效期限：{{data.expires_time}}</div>
                                    <div class="rules" @click.stop="viewCardRules(index,dataIndex)">使用规则></div>
                                </div>
                            </coupon-card-v1>
                        </template>
                        <template v-if="index == 3">
                            <coupon-card-v1 :class="[index == dataList[index].length - 1 ? 'marginBottomZero' : '']"
                                            v-for="(data,dataIndex) in dataList[index]" :card="data">
                                <div class="small-info">
                                    <div class="expire-time">有效期限：{{data.expires_time}}</div>
                                    <div class="rules" @click.stop="viewCardRules(index,dataIndex)">使用规则></div>
                                </div>
                            </coupon-card-v1>
                        </template>
                    </rsck-refresh>
                </div>
            </nut-tab-panel>
        </tab>


        <div class="bottom-button" @click="showTotalRule">
            <img src="https://api.mengjingloulan.com/storage/wechat/coupon-card/rule.png"/> 礼品卡使用规则
        </div>

        <nut-popup
                :style="{ height:'60%' }"
                v-model="show"
                position="bottom"
                :closeable="true"
                :round="true"
                @close="() => {ruleText = ''}"
        >
            <div class="popup-container">
                <div class="title">礼品卡使用规则</div>
                <div class="rules" v-html="ruleText"></div>
            </div>

        </nut-popup>
    </div>
</template>

<script>
  import RsckRefresh from '@/components/common/rsck-refresh'
  import Tab from '../../components/tab/Tab'
  import CouponCardV1 from '../../template/coupon-card/coupon-card-v1'

  export default {
    name: 'home',
    components: {RsckRefresh, Tab, CouponCardV1},
    data () {
      return {
        scrollHeight: 0,
        dataList: [],
        show: false,
        catalog: [
          {lable: '全部', value: -1},
          {lable: '已领取', value: 1},
          {lable: '已过期', value: 2},
          {lable: '已转赠', value: 3},
        ],
        showResult: false,
        showIndex: 0,
        params: [],
        ruleText: '',
        baseRuleText: '',
        isLoading: false,
        tabHeight: 0,
        hasMore: [],
        canContinue: false,
      }
    },
    mounted () {
      document.title = '我的礼品卡'
      this.gitRule()
      this.canContinue = true
      this.init()
      setTimeout(() => {
        this.scrollHeight = window.innerHeight - document.querySelector('.bottom-button').clientHeight - document.querySelector('.nut-tab-title').clientHeight - 10
        this.tabHeight = document.querySelector('.nut-tab-title').clientHeight
        this.tabSwitch(0)
      }, 500)

    },
    methods: {
      gitRule () {
        this.$api.couponCard.gitRule().then(response => {
          this.baseRuleText = response.data
        })
      },
      onPullDown () {
        //触发下拉属性
        let that = this
        this.dataList[this.showIndex] = []
        this.params[this.showIndex].page = 1
        this.hasMore[this.showIndex] = true
        this.getCardList()
      },
      onPullUp () {
        //触发上拉加载
        this.getCardList()
      },
      tabSwitch (e) {
        this.showIndex = e
        if (this.dataList[e].length == 0) {
          this.getCardList()
        }
      },
      init () {
        let params = []
        let hasMore = []
        this.catalog.forEach(item => {
          params.push({
            status: item.value,
            page: 1,
            page_size: 5
          })
          this.dataList.push([])
          this.hasMore.push(true)
        })
        this.params = params
        //默认调用完分类后，调用分类下的优惠卡列表
        this.tabSwitch(0)
      },
      async getCardList () {
        let index = this.showIndex//正在展示的是那个页卡
        let params = this.params[index]
        if (!this.isLoading) {
          this.isLoading = true
          await this.$api.couponCard.getCouponCardList(params).then(response => {
            if (this.dataList[index].length == 0) {
              this.dataList[index] = response.data
            } else {
              response.data.forEach(item => {
                this.dataList[index].push(item)
              })
            }

            if (response.data.length < params.page_size) {
              this.hasMore[index] = false
            }

            params.page++
            this.params[index] = params
            this.isLoading = false
            this.$refs['refresh' + '_' + this.showIndex][0].endPullUp(!this.hasMore[this.showIndex])
            this.$refs['refresh' + '_' + this.showIndex][0].endPullDown()
            this.$forceUpdate()
          })
        }

      },
      scanQRCode (card_id, order_id, order_sn) {
        if (this.canContinue && this.$platform.wxsdk.isWechat()) {
          this.canContinue = false
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            // type: 'redirectTo',
            url: '/web/scancode/couponCard?coupon_id=' + card_id + '&order_id=' + order_id + '&order_sn=' + order_sn,
          })
          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }
      },
      goToUsePage (data) {
        if (this.canContinue && [1, 2,3].includes(data.status)) {
          this.canContinue = false
          let options = encodeURIComponent(JSON.stringify({order_id: data.id}))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/couponCard/card-use?query=' + options,
          })
          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }
      },
      viewCardRules (index, dataIndex) {
        this.ruleText = this.dataList[index][dataIndex].rule_content
        this.show = true
      },
      showTotalRule () {
        this.ruleText = this.baseRuleText
        this.show = true
      }
    }
  }
</script>

<style scoped lang="scss">
    .page-container {
        background: #f6f6f6;
        height: 100vh;

        .bottom-button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 40px;
            background: #fff;
            border-top: #EBEBEB solid 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #F46A17;
            z-index: 99;

            img {
                width: 16px;
                height: 16px;
                margin-right: 3px;
            }
        }
    }

    /deep/ .nut-tab {
        padding: 0;
        border: none;
    }

    /deep/ .nut-tab-title {
        border-bottom: none;
        position: sticky !important;
        top: 0;
    }

    /deep/ .nut-tab-item {
        background: #f6f6f6;
        border: none;
        padding: 0;
    }

    /deep/ .nut-tab-active a {
        color: #F46A17;
    }

    .small-info {
        width: 100%;
        height: 12px;
        margin: 10px 0;
        display: flex;

        .expire-time {
            flex: 1;
            color: #939393;
            font-size: 12px;
            display: flex;
            justify-content: flex-start;
        }

        .rules {
            flex: 1;
            color: #F46A17;
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .buttons {
        width: 100%;
        display: flex;

        .send {
            width: 336px;
            height: 34px;
            background: #FFECE1;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            font-size: 14px;
            color: #F46A17;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 9px;
        }

        .use {
            width: 336px;
            height: 34px;
            background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            font-size: 14px;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .space-line {
        width: 100%;
        height: 10px;
    }

    .marginBottomZero {
        margin-bottom: 0;
    }

    .popup-container {
        padding: 30px;

        .title {
            width: 100%;
            font-size: 14px;
            color: #333;
        }

        .rules {
            margin-top: 10px;
            line-height: 25px;
            font-size: 14px;
            color: #939393;
        }
    }

</style>